import * as React from 'react';
import { cn } from '../../util';

import styles from "./SquareImageWrapper.module.css";

export interface SquareImageWrapperProps {
    className?: string;
    style?: React.CSSProperties;
}

const SquareImageWrapper: React.SFC<SquareImageWrapperProps> = ({ className, style, children }) => {
    return (
        <div className={cn(styles.squareImageWrapper, className)} style={style}>
            <div className={cn(styles.square)}>
            </div>
            <div className={cn(styles.squareImage)}>
                {children}
            </div>
        </div>
    );
}

export default SquareImageWrapper;
