import * as React from 'react';

import Modal from './Modal';
import OptInForm, { NameField, EmailField, SubmitButton, ErrorLabel, CompanyField } from './OptInForm';
import processText from '../../views/processText';

import styles from "./LoginSignupModal.module.css";
import { cn } from '../../util';
import Row from './Row';
import Col from './Col';
import Label from './Label';

const SIGNUP_URL = "https://app.dockington.com/api/signup";

export interface LoginSignupModalProps {
    isOpen: boolean,
    onClose: () => void,
    translate: (key: string) => string,
}

export interface LoginSignupModalState {

}

class LoginSignupModal extends React.Component<LoginSignupModalProps, LoginSignupModalState> {
    onClose = () => {
        if ((window as any).gtag) {
            (window as any).gtag('event', "login-signup-close", {
                'event_category' : 'login-signup-form',
                'event_label' : "Close login signup popup"
            });
        }
        this.props.onClose();
    }
    onSubscribe = (data: any) => {
        const win = window as any;
        if (data.redirect) {
            if (win.gtag) {
                win.gtag('event', 'conversion', {'send_to': 'AW-840946225/xGOpCOz2x8cBELGk_5AD'});
                win.gtag('event', "login-signup-success", {
                    'event_category' : 'login-signup-form',
                    'event_label' : "Successfully signed up from login modal"
                });
            }
            window.location.href = data.redirect;
        } else {
            if (win.gtag) {
                win.gtag('event', "login-signup-error", {
                    'event_category' : 'login-signup-form',
                    'event_label' : "Missing signup redirect from signup response"
                });
            }
        }
    }
    render() {
        const { isOpen, translate } = this.props;
        const content = translate("main.login") as any;
        return (
            <Modal
                isOpen={isOpen}
                contentLabel="Login"
                onClose={this.onClose}
                closeBtnColor="#bbb"
                className={styles.modal}
                contentClassName={styles.modalBody}
            >
                <OptInForm
                    companyRequired
                    signUpUrl={SIGNUP_URL}
                    onSubscribe={this.onSubscribe}
                    translate={translate}
                >
                    <div className={styles.title}>
                        <h2>
                            {processText(content.title)}
                        </h2>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.text}>
                            {processText(content.text)}
                        </div>
                        <Label label={translate("common.forms.company")}>
                            <CompanyField />
                        </Label>
                        <Label label={translate("common.forms.name")}>
                            <NameField />
                        </Label>
                        <Label label={translate("common.forms.email")}>
                            <EmailField />
                        </Label>
                        <ErrorLabel />
                        <Row className="pt-05">
                            <Col xs12 s8 className={styles.disclaimer}>
                                {processText(content.disclaimer)}
                            </Col>
                            <Col xs12 s4 className="text-right">
                                <SubmitButton
                                    className={cn("ctaButton", styles.submit)}
                                    actionText={content.login}
                                    loadingText={content.loggingIn}
                                />
                            </Col>
                        </Row>
                        <div className="text-right">
                        </div>
                    </div>
                </OptInForm>
            </Modal>
        );
    }
}

export default LoginSignupModal;
