import * as React from 'react';

import { cn } from '../../util';
import styles from "./WordsSlider.module.css";

export interface WordsSliderProps {
    centerSmall?: boolean;
    words: {
        word: string,
        className?: string,
    }[],
    className?: string,
    style?: React.CSSProperties;
}

export interface WordsSliderState {
    activeWord: number,
}

class WordsSlider extends React.Component<WordsSliderProps, WordsSliderState> {
    slideTimer?: NodeJS.Timeout;

    constructor(props: WordsSliderProps) {
        super(props);
        this.state = {
            activeWord: 0,
        };
        this.slide = this.slide.bind(this);
    }

    componentDidMount() {
        this.slideTimer = setInterval(this.slide, 3600);
    }

    componentWillUnmount() {
        if (this.slideTimer) {
            clearInterval(this.slideTimer);
            this.slideTimer = undefined;
        }
    }

    slide() {
        this.setState({ activeWord: (this.state.activeWord + 1) % this.props.words.length });
    }

    render() {
        const { centerSmall, className, style, words } = this.props;
        const { activeWord } = this.state;
        const lastActive = activeWord > 0 ? (activeWord - 1) : (words.length - 1);
        return (
            <div className={cn(styles.slider, className)} style={style}>
                <div className={cn(styles.slideAnchor, centerSmall && styles.centerSmall)}>
                    {words.map((word, i) => (
                        <div className={cn(i === activeWord && styles.visible, i !== activeWord && i === lastActive && styles.hidden, word.className)}>{word.word}</div>
                    ))}
                </div>
            </div>
        );
    }
}

export default WordsSlider;
