import * as React from 'react';
import RequiredLabel from './RequiredLabel';
import { cn } from '../../util';

export interface LabelProps {
    label: string,
    required?: boolean,
    requiredOneOf?: boolean | number,
    className?: string,
    style?: React.CSSProperties,
    labelStyle?: React.CSSProperties,
}

const Label: React.SFC<LabelProps> = ({ label, required, requiredOneOf, className, style, labelStyle, children }) => {
    return (
        <div className={cn("label", className)} style={style}>
            <label style={labelStyle}>
                {label}{required && <RequiredLabel oneOf={requiredOneOf}/>}
            </label>
            {children}
        </div>
    );
}

export default Label;
