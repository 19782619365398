import React, { Component } from 'react';
import { Collapse } from 'react-collapse';
import GatsbyImage from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withTranslate } from "../../components/translate";
import Jumbotron from '../../components/common/Jumbotron';
import ScrollToTopOnMount from '../../components/common/ScrollToTopOnMount';
import SEO from '../../components/SEO';
import Row from '../../components/common/Row';
import Col from '../../components/common/Col';
import FlexRow from '../../components/common/FlexRow';
import FlexCell from '../../components/common/FlexCell';
import List from '../../components/common/List';
import { ImageHighlight, StepTimer } from '../../components/common/ProductHighlight';
import { createSteps } from './Solution';
import { ElenaSamuliMessage } from '../AboutPage/AboutPage';
import JumbotronFluid from '../../components/common/JumbotronFluid';
import FAQ from './FAQ';
import RevealList from '../../components/common/RevealList';
import NavBarDark from '../../components/common/NavBarDark';
import LoginSignupModal from '../../components/common/LoginSignupModal';
import Header from './Header';
import CTAButton from './CTAButton';

import styles from "./MainPage.module.css";
import "./MainPage.css";

import processText from '../processText';
import { cn } from '../../util';


const SplitJumbo = ({ className, style, colorTop, colorBottom, children }) => {
    return (
        <section style={{ position: "relative", padding: "2rem 1rem" }}>
            <div style={{ backgroundColor: colorTop, position: "absolute", top: 0, bottom: "50%", left: 0, right: 0 }} />
            <div style={{ backgroundColor: colorBottom, position: "absolute", top: "50%", bottom: 0, left: 0, right: 0 }} />
            <div className={cn("container", className)} style={style}>
                {children}
            </div>
        </section>
    );
}
const Container = ({ children }) => {
    return (
        <div className="container">
            {children}
        </div>
    );
}

const ContentCard = ({ icon, above, below }) => {
    return (
        <Col xs12 s6 l3 className="my-05 px-05 my-lg-0">
            <div className={cn(styles.contentCard, styles.boxShadow)}>
                <div className={cn(styles.contentCardIcon, Array.isArray(icon) && styles.smallIcons)}>
                    {Array.isArray(icon)
                        ? icon.map(ic => <FontAwesomeIcon key={ic} style={{ width: "1em" }} {...(ic.icon ? ic : { icon: ic })} />)
                        : <FontAwesomeIcon style={{ width: "1em" }} {...(icon.icon ? icon : { icon })} />
                    }
                </div>
                <div>
                    <div className={cn(styles.contentCardText, above.highlight && styles.highlight)}>
                        {above.text || above}
                    </div>
                    <div className={cn(styles.contentCardText, below.highlight && styles.highlight)}>
                        {below.text || below}
                    </div>
                </div>
            </div>
        </Col>
    );
}

class ModuleDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }
    onClick = () => {
        const isOpen = this.state.isOpen;
        this.setState({ isOpen: !isOpen });
        if (window.gtag) {
            const moduleName = this.props.mod.title.toLowerCase().replace(/ /g, "-");
            window.gtag('event', `main-module-${isOpen ? 'close' : 'open'}-${moduleName}`, {
                'event_category' : 'main-module-toggle',
                'event_label' : `${isOpen ? 'Close' : 'Open'} module ${this.props.mod.title}`
            });
        }
    }
    render() {
        const { mod, className } = this.props;
        const { isOpen } = this.state;
        return (
            <div className={className}>
                <FlexRow onClick={this.onClick} className={cn(styles.moduleTitle, "clickable")}>
                    <FlexCell className={styles.moduleNumber}>{mod.module}</FlexCell>
                    <FlexCell className={styles.moduleName}>{mod.title}</FlexCell>
                    <FlexCell className={styles.moduleOpenIcon}>
                        <FontAwesomeIcon width="1em" className={isOpen ? "caret-open" : "caret-closed"} icon={"caret-down"} />
                    </FlexCell>
                </FlexRow>
                <Collapse isOpened={isOpen}>
                    <ModuleContent content={mod.content} />
                </Collapse>
            </div>
        );
    }
}

const ModuleContent = ({ content }) => {
    return (
        <div className={styles.moduleContent}>
            <p className="mb-05">{content.text}</p>
            <ol>
                {content.points.map((point) => (
                    <li key={point}>{point}</li>
                ))}
            </ol>
        </div>
    );
}

const ForWhomCard = ({ icon, title, text }) => {
    return (
        <div className={styles.forWhomCard}>
            <div className={styles.forWhomIcon}>
                <FontAwesomeIcon style={{ width: "1.25em" }} icon={icon} />
            </div>
            <div className={styles.forWhomText}>
                <h4>{title}</h4>
                <p>{text}</p>
            </div>
        </div>
    );
}

const CollateralPoints = ({ points }) => {
    return (
        <RevealList
            className={styles.collateralPoints}
            items={points.map((text) => ({
                marker: <FontAwesomeIcon icon="check" />,
                text: text,
            }))}
        />
    );
}

class ProductHighlight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: createSteps(props.images, props.translate),
        };
    }
    onRender = ({ slots }) => {
        return (
            <ImageHighlight
                animateSmall
                steps={this.state.steps}
                positions={slots}
                translate={this.props.translate}
            />
        );
    }
    render() {
        return (
            <div className={styles.productHighlight}>
                <StepTimer steps={this.state.steps} render={this.onRender} />
            </div>
        );
    }
}

const ReviewCard = ({ review, images }) => {
    return (
        <div className={cn(styles.reviewCard, review.male ? styles.male : styles.female, "shadow")}>
            <div className={styles.reviewImageContainer}>
                <GatsbyImage
                    className={styles.reviewImage}
                    fixed={images[review.image.src]}
                    alt={review.image.alt}
                />
            </div>
            <div className={styles.reviewBody}>
                &ldquo;{processText(review.body)}&rdquo;
            </div>
            <div className={styles.reviewSignature}>
                <div className="signature">{review.author.name}</div>
                <div className={styles.reviewTitle}>{processText(review.author.title)}</div>
            </div>
        </div>
    );
}

const SectionTitle = ({ id, className, title, subtitle, small, center }) => {
    return (
        <div className={cn(styles.sectionTitle, className, small && styles.smallTitle, center && "text-center")}>
            <h2 id={id}>{title}</h2>
            {subtitle && <div>{subtitle}</div>}
        </div>
    );
}

const PackageCard = ({ pkg, pricePerMonth, onClick }) => {
    return (
        <div className={cn(styles.packageCard, styles.boxShadow, styles.adjustHeight)} onClick={onClick}>
            <div className={styles.packageName}>
                <h4>{pkg.name}</h4>
            </div>
            <div className={styles.packageBody}>
                <div className={styles.price}>
                    {pkg.price}
                    <div className={styles.pricePerMonth}>{pricePerMonth}</div>
                </div>
                <Row justify="center" className="mx-0">
                    <List
                        items={pkg.includes.map(item => ({
                            marker: <FontAwesomeIcon icon="check" />,
                            text: processText(item),
                        }))}
                    />
                </Row>
            </div>
        </div>
    );
}

const ModuleCard = ({ mod, pricePerMonth, onClick }) => {
    return (
        <div className={cn(styles.packageCard, styles.boxShadow, styles.module)} onClick={onClick}>
            <div className={cn(styles.packageName, styles.moduleIcon)}>
                <FontAwesomeIcon icon={mod.icon} />
                <h4>{mod.name}</h4>
            </div>
            <div className={styles.packageBody}>
                <div className={styles.price}>
                    {mod.price}
                    <div className={styles.pricePerMonth}>{pricePerMonth}</div>
                </div>
            </div>
        </div>
    );
}

const MainPage = withTranslate(function (props) {
    const [showLoginPopup, setShowLoginPopup] = React.useState(false);
    const toggleLoginPopup = () => {
        setShowLoginPopup(!showLoginPopup);
    };

    const translate = (key, toProcess) => {
        const value = props.translate(key);
        if (typeof value === "string") {
            return value;
        } else if (typeof toProcess === "boolean") {
            return toProcess ? processText(value) : value;
        } else if (Array.isArray(toProcess)) {
            return {
                ...value,
                ...toProcess.reduce((map, processKey) => ({ ...map, [processKey]: processText(value[processKey]), }), {}),
            };
        } else {
            return processText(value);
        }
    };
    return (
        <div className="mainpage">
            <SEO
                title={translate("main.head.title")}
                description={translate("main.head.description")}
                keywords={translate("main.head.keywords")}
                path="/"
            />
            <ScrollToTopOnMount />
            <NavBarDark onClickCta={toggleLoginPopup} translate={translate} />
            <Header onClickCta={toggleLoginPopup} images={props.images} translate={translate} />
            <Jumbotron color="#fafafa" className="pb-0 pt-lg-0 text-center">
                <SectionTitle
                    id="software"
                    className={cn(styles.accessNowTitle, "pt-sm-2 pt-lg-0 pb-2 mb-0")}
                    title={translate("main.accessNow.title")}
                />
            </Jumbotron>
            <SplitJumbo colorTop="#fafafa" colorBottom="#fff">
                <Row className="mx-xl-3">
                    {props.translate("main.accessNow.cards").map((card) => (
                        <ContentCard
                            key={card.icon}
                            icon={card.icon}
                            above={card.above}
                            below={card.below}
                        />
                    ))}
                </Row>
            </SplitJumbo>
            <Jumbotron color="#fff">
                <Container>
                    <Row>
                        <Col xs12 l6>
                            <SectionTitle
                                title={translate("main.makeYourOwn.title")}
                                subtitle={translate("main.makeYourOwn.subtitle")}
                            />
                        </Col>
                        <Col xs12 l6 className="pl-lg-2">
                            {props.translate("main.makeYourOwn.modules").map((mod, idx) => (
                                <ModuleDropdown key={mod.module} mod={mod} className={idx > 0 ? styles.moduleBorderTop : undefined} />
                            ))}
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Jumbotron color="#fafafa" className="pt-3 pb-0" style={{ marginBottom: "-40px" }}>
                <SectionTitle
                    id="forWhom"
                    center
                    className="mt-1"
                    title={translate("main.forWhom.title")}
                    subtitle={translate("main.forWhom.subtitle")}
                />
                <Row className="px-1 px-sm-3 px-lg-5 mx-lg-2">
                    <Col xs12 m6 className="mb-1 mb-md-0">
                        <ForWhomCard {...translate("main.forWhom.artisan", ["title", "text"])} />
                    </Col>
                    <Col xs12 m6>
                        <ForWhomCard {...translate("main.forWhom.retailer", ["title", "text"])} />
                    </Col>
                </Row>
            </Jumbotron>
            <Jumbotron color="#333232" className="white pb-3" style={{ paddingTop: "110px" }}>
                <Row>
                    <Col xs12 l6>
                        <SectionTitle
                            id="why"
                            className="mt-0"
                            title={translate("main.collateral.title")}
                            subtitle={translate("main.collateral.subtitle")}
                        />
                    </Col>
                    <Col xs12 l6>
                        <CollateralPoints points={translate("main.collateral.points")} />
                    </Col>
                </Row>
            </Jumbotron>
            <JumbotronFluid className="px-0" containerClassName="container-fluid px-0" color="#fafafa">
                <SectionTitle
                    center
                    className="px-1"
                    title={translate("main.whatYouFind.title")}
                    subtitle={translate("main.whatYouFind.subtitle")}
                />
                <ProductHighlight images={props.images} translate={props.translate} />
            </JumbotronFluid>
            <Jumbotron color="#fff">
                <SectionTitle
                    center
                    id="whoAreWe"
                    title={translate("main.facesBehind.title")}
                    className="mb-1"
                />
                <div className="pb-2">
                    {props.translate("main.facesBehind.messages").map((message) => (
                        <ElenaSamuliMessage {...message} />
                    ))}
                </div>
            </Jumbotron>
            <Jumbotron color="#fafafa">
                <SectionTitle
                    center
                    title={translate("main.takeAdvice.title")}
                    subtitle={translate("main.takeAdvice.subtitle")}
                />
                <Row>
                    {props.translate("main.takeAdvice.reviews").map(review => (
                        <Col key={review.author.name} xs12 m4 className="px-2 px-md-05 px-lg-1">
                            <ReviewCard review={review} images={props.images} />
                        </Col>
                    ))}
                </Row>
                <div className="cta text-center pt-5 pb-3">
                    <h2>{translate("main.takeAdvice.cta")}</h2>
                    <CTAButton onClick={toggleLoginPopup} name="reviews">
                        {translate("main.action.button")}
                    </CTAButton>
                </div>
            </Jumbotron>
            <Jumbotron color="#333232" className="white" style={{ paddingBottom: "160px" }}>
                <SectionTitle
                    center
                    id="pricing"
                    title={translate("main.littleOrMuch.title")}
                    subtitle={translate("main.littleOrMuch.subtitle")}
                />
                <Row justify="center">
                    <Col xs12 m6 l5 className="mb-2 mb-md-0">
                        <p className="mb-2">
                            {translate("main.littleOrMuch.much.goal")}
                        </p>
                        <p>
                            {translate("main.littleOrMuch.much.answer")}
                        </p>
                    </Col>
                    <Col xs12 m6 l5>
                        <p className="mb-2">
                            {translate("main.littleOrMuch.little.goal")}
                        </p>
                        <p>
                            {translate("main.littleOrMuch.little.answer")}
                        </p>
                    </Col>
                </Row>
            </Jumbotron>
            <Jumbotron color="#fff" className="pt-0" containerClassName={styles.packagesContainer}>
                <div className={cn(styles.packagesHighlight, "mx-lg-2")} />
                <div className={cn(styles.packages, "px-1 px-lg-2 px-xl-3 pt-3 pb-2 mx-lg-2 shadow-lg")}>
                    <SectionTitle
                        center
                        title={translate("main.packages.title")}
                        subtitle={translate("main.packages.subtitle")}
                    />
                    <h3>{translate("main.packages.ready.title")}</h3>
                    <Row justify="center" className="mb-3">
                        {props.translate("main.packages.ready.packages").map(pkg => (
                            <Col key={pkg.name} xs12 s6 m4 className="mb-2">
                                <PackageCard
                                    pkg={pkg}
                                    pricePerMonth={translate("main.packages.pricePerMonth")}
                                    onClick={toggleLoginPopup}
                                />
                            </Col>
                        ))}
                    </Row>
                    <h3>{translate("main.packages.single.title")}</h3>
                    <Row justify="center" className="mb-1">
                        {props.translate("main.packages.single.modules").map(mod => (
                            <Col key={mod.name} xs12 s6 m4 xl3>
                                <ModuleCard
                                    mod={mod}
                                    pricePerMonth={translate("main.packages.pricePerMonth")}
                                    onClick={toggleLoginPopup}
                                />
                            </Col>
                        ))}
                    </Row>
                    <p className={styles.payYearly}>
                        <FontAwesomeIcon icon="exclamation-triangle" color="#ffc107" /> {translate("main.packages.payYearlyAndSave")}
                    </p>
                </div>
            </Jumbotron>
            <Jumbotron color="#fafafa">
                <SectionTitle
                    center
                    title={translate("main.faq.title")}
                />
                <FAQ
                    points={props.translate("main.faq.questions")}
                />
            </Jumbotron>
            <Jumbotron color="#fff" className="cta text-center py-5">
                <h2>{translate("main.action.title")}</h2>
                <CTAButton onClick={toggleLoginPopup} name="bottom">
                    {translate("main.action.button")}
                </CTAButton>
            </Jumbotron>
            <LoginSignupModal
                isOpen={showLoginPopup}
                onClose={toggleLoginPopup}
                translate={props.translate}
            />
        </div>
    )
});

export default MainPage;
