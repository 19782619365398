import * as React from 'react';
import { cn } from '../../util';

export interface CTAButtonProps {
    small?: boolean;
    className?: string;
    style?: React.CSSProperties;
    name: string;
    onClick: () => void;
}

class CTAButton extends React.Component<CTAButtonProps> {

    onClick = () => {
        const gtag = (window as any).gtag;
        if (gtag) {
            gtag('event', `trial-cta-${this.props.name}`, {
                'event_category' : 'trial-cta',
                'event_label' : `Trial CTA ${this.props.name}`
            });
        }
        this.props.onClick();
    }

    render() {
        const { small, className, style, children } = this.props;
        return (
            <button className={cn("ctaButton", small && "smallCta", className)} style={style} onClick={this.onClick}>
                {children}
            </button>
        );
    } 
} 

export default CTAButton;
