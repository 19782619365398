import * as React from 'react';
import GatsbyImage from 'gatsby-image';

import Row from '../../components/common/Row';
import Col from '../../components/common/Col';
import SquareImageWrapper from '../../components/common/SquareImageWrapper';
import CTAButton from './CTAButton';

import { cn } from '../../util';

import styles from "./Header.module.css";
import WordsSlider from '../../components/common/WordsSlider';

export interface HeaderProps {
    images: {
        [name: string]: any;
    },
    onClickCta: () => void;
    translate: (key: string) => string;
}

const Header: React.SFC<HeaderProps> = ({ images, onClickCta, translate }) => {
    return (
        <header className={cn(styles.header, "")}>
            <div className={cn("container", styles.headerContainer)}>
                <Row className={cn(styles.headerRow, "")}>
                    <Col xs12 l6 className={cn(styles.headerText)}>
                        <h1>
                            {translate("main.grab.tagline.title")}<br/>
                            <WordsSlider
                                centerSmall
                                className={styles.wordSlider}
                                words={
                                    (translate("main.grab.tagline.subtitle") as any)
                                    .map((word: string) => ({ word}))
                                }
                            />
                        </h1>
                        <div className={styles.ctaContainer}>
                            <CTAButton onClick={onClickCta} name="header">
                                {translate("main.action.button")}
                            </CTAButton>
                        </div>
                    </Col>
                    <Col xs12 l6 className={styles.headerImage}>
                        <SquareImageWrapper className={styles.headerMainImage}>
                            <div className={styles.headerPic}>
                                <GatsbyImage
                                    className={styles.headerPicMainPage}
                                    fluid={images.invoicePage}
                                    alt={translate("main.grab.imageAlt")}
                                />
                                <GatsbyImage
                                    className={styles.headerPicMainPdf}
                                    fluid={images.invoicePdf}
                                    alt={translate("main.grab.imageAlt")}
                                />
                            </div>
                        </SquareImageWrapper>
                    </Col>
                </Row>
            </div>
        </header>
    );
}

export default Header;
