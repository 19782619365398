import React from "react";
import GatsbyImage from "gatsby-image";

import ProductHighlight, { VideoHighlight } from "../../components/common/ProductHighlight";
import Dockington from "../../components/common/Dockington";

import articleAutofill from "../../assets/images/article_autofill_small.mp4";
import articleAutofillMobile from "../../assets/images/article_autofill_small.webm";

export const createSteps = function (images, translate) {
    return [
        {
            title: translate("main.solution.status.title"),
            text: translate("main.solution.status.text"),
            component: () => (
                <GatsbyImage
                    className="product-img"
                    fluid={images.statusHighlight}
                    alt={translate("main.solution.status.imgAlt")}
                />
            ),
        },
        {
            title: translate("main.solution.documents.title"),
            text: translate("main.solution.documents.text"),
            timeout: 17000,
            component: ({ active }) => (
                <VideoHighlight
                    playing={active}
                    className="product-img img-shadow"
                >
                    <source src={articleAutofill} type="video/mp4"/>
                    <source src={articleAutofillMobile} type="video/webm"/>
                    {translate("main.solution.documents.imgAlt")}
                </VideoHighlight>
            ),
        },
        {
            title: translate("main.solution.search.title"),
            text: translate("main.solution.search.text"),
            component: () => (
                <GatsbyImage
                    className="product-img"
                    fluid={images.invoiceHighlight}
                    alt={translate("main.solution.search.imgAlt")}
                />
            ),
        },
    ];
}

export const SolutionProductHighlight = ({ translate }) => {
    return (
        <ProductHighlight
            steps={createSteps(translate)}
        />
    );
}

const Solution = function ({ translate }) {
    return (
        <section>
            <div className="container">
                <div className="intro mt-3 mb-4">
                    <h2>
                        {translate("main.solution.title").map((part, i) => (part.dockington ? <Dockington key={i} /> : part))}
                    </h2>
                    <p id="product" className="h2-sub">
                        {translate("main.solution.subtitle").map((part) => (part.emph ? <span className="emph">{part.emph}</span> : part))}
                    </p>
                </div>
            </div>
            <SolutionProductHighlight translate={translate} />
        </section>
    );
}

export default Solution;
