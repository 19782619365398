import * as React from 'react';
import RevealWhenVisible from './RevealWhenVisible';

export interface RevealListProps {
    simple?: boolean,
    className?: string,
    items: any[],
}

const RevealList: React.SFC<RevealListProps> = ({ simple, className, items }) => {
    return (
        <div className={"list" + (simple ? " simple" : "") + (className ? (" " + className) : "")}>
            {items.map((item) => (
                <RevealWhenVisible key={item.text || item} className="list-item">
                    {(item.marker || simple) && <div className="list-marker">{item.marker || "- "}</div>}
                    <div>{item.text ? item.text : item}</div>
                </RevealWhenVisible>
            ))}
        </div>
    );
}

export default RevealList;
