import React from "react"
import { graphql } from "gatsby";

import Layout from "../components/Layout"
import MainPage from "../views/MainPage"

export const query = graphql`
{
    invoicePage: file(relativePath: { eq: "invoice_page.png" }) {
        childImageSharp {
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    invoicePdf: file(relativePath: { eq: "invoice_pdf.png" }) {
        childImageSharp {
            fluid(maxWidth: 250) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    mauro: file(relativePath: { eq: "mauro.png" }) {
        childImageSharp {
            fixed(width: 100) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    aurora: file(relativePath: { eq: "aurora.png" }) {
        childImageSharp {
            fixed(width: 100) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    biancoline: file(relativePath: { eq: "biancoline-small.png" }) {
        childImageSharp {
            fixed(width: 100) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    invoiceHighlight: file(relativePath: { eq: "invoice_small.png" }) {
        childImageSharp {
            fluid(maxWidth: 505) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    statusHighlight: file(relativePath: { eq: "status_small.png" }) {
        childImageSharp {
            fluid(maxWidth: 505) {
                ...GatsbyImageSharpFluid
            }
        }
    }
}
`;

const IndexPage = ({ location, data, pageContext: { locale } }) => {
    const images = {
        invoicePage: data.invoicePage.childImageSharp.fluid,
        invoicePdf: data.invoicePdf.childImageSharp.fluid,
        mauro: data.mauro.childImageSharp.fixed,
        aurora: data.aurora.childImageSharp.fixed,
        biancoline: data.biancoline.childImageSharp.fixed,
        invoiceHighlight: data.invoiceHighlight.childImageSharp.fluid,
        statusHighlight: data.statusHighlight.childImageSharp.fluid,
    };
    return (
        <Layout location={location} locale={locale}>
            <MainPage images={images} />
        </Layout>
    );
};

export default IndexPage;
