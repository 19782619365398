import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from "./Link";
import CTAButton from '../../views/MainPage/CTAButton';

import processText from '../../views/processText';

import "./NavBar.css";

export interface NavBarDarkProps {
    onClickCta: () => void,
    translate: (key: string) => string,
}

export interface AnalyticsLinkProps {
    className?: string,
    to: string,
    name: string,
    onClick: () => void,
}

class AnalyticsLink extends React.Component<AnalyticsLinkProps> {
    onClick = () => {
        const win = window as any;
        if (win.gtag) {
            const { name } = this.props;
            win.gtag('event', `main-nav-click-${name}`, {
                'event_category' : 'main-nav',
                'event_label' : `Click main nav ${name}`
            });
        }
        this.props.onClick();
    }
    render() {
        const { className, to } = this.props;
        return (
            <Link className={className} to={to} onClick={this.onClick}>
                {this.props.children}
            </Link>
        );
    }
}

const NavBarDark: React.SFC<NavBarDarkProps> = ({ onClickCta, translate }) => {
    const [collapsed, setCollapsed] = React.useState(true);
    const hideCollapse = () => {
        setCollapsed(true);
    }
    return (
        <div className="fixed-top">
            <nav className={"navbar navbar-expand-md dark" + (collapsed ? "" : " nav-show-bg")}>
                <div className="container px-0">
                    <div className="navbar-header">
                        <AnalyticsLink
                            className="navbar-brand lobster"
                            to="/#"
                            name="brand"
                            onClick={hideCollapse}
                        >
                            Dockington
                        </AnalyticsLink>
                    </div>
                    <div>
                        <button
                            type="button"
                            className={"navbar-toggler" + (collapsed ? " collapsed" : "")}
                            aria-controls="navbar-collpse"
                            aria-expanded="false"
                            onClick={() => { setCollapsed(!collapsed); }}
                        >
                            <span className="sr-only">Toggle navigation</span>
                            <FontAwesomeIcon style={{ width: "0.875em" }} icon="bars" />
                        </button>
                    </div>

                    <div className={"collapse navbar-collapse" + (collapsed ? "" : " show")} id="navbar-collpse">
                        <ul className="nav navbar-nav ml-auto">
                            <li className="nav-item">
                                <AnalyticsLink className="nav-link" to="/#software" name="software" onClick={hideCollapse}>
                                    <div className="nav-text">{processText(translate("nav.software"))}</div>
                                </AnalyticsLink>
                            </li>
                            <li className="nav-item">
                                <AnalyticsLink className="nav-link" to="/#forWhom" name="for-whom" onClick={hideCollapse}>
                                    <div className="nav-text">{processText(translate("nav.forWhom"))}</div>
                                </AnalyticsLink>
                            </li>
                            <li className="nav-item">
                                <AnalyticsLink className="nav-link" to="/#why" name="why" onClick={hideCollapse}>
                                    <div className="nav-text">{translate("nav.why")}</div>
                                </AnalyticsLink>
                            </li>
                            <li className="nav-item">
                                <AnalyticsLink className="nav-link" to="/#whoAreWe" name="who-are-we" onClick={hideCollapse}>
                                    <div className="nav-text">{translate("nav.whoAreWe")}</div>
                                </AnalyticsLink>
                            </li>
                            <li className="nav-item">
                                <AnalyticsLink className="nav-link" to="/#pricing" name="pricing" onClick={hideCollapse}>
                                    <div className="nav-text">{translate("nav.pricing")}</div>
                                </AnalyticsLink>
                            </li>
                            <li className="nav-item pl-1">
                                <CTAButton
                                    small
                                    onClick={() => {
                                        hideCollapse();
                                        onClickCta();
                                    }}
                                    name="navbar"
                                >
                                    {translate("main.action.buttonNav")}
                                </CTAButton>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default NavBarDark;
